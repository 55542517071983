import imagen from './assets/images/under_construction.jpg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Sitio web en construcción</p>
        <img src={imagen} alt="imagen" />
      </header>
    </div>
  );
}

export default App;
